import React from 'react';
import './BioPage.css';

import logo from '../assets/img/lecadi_amarelo.png'
import torinhas from "../assets/img/tortinhas.jpeg"
import arrowImg from "../assets/img/Right Arrow.png"
import wppImg from "../assets/img/WhatsApp.png"
import instaImg from "../assets/img/Instagram.png"

function BioPage() {
    return (
        <div className="body">
            <main className="container">
                <img src={logo} alt='Logo Le Cadi' className='logo' />
                <div className='container-info'>
                    <div className='tortinhas-div'>
                        <img src={torinhas} alt='Tortinhas Le Cadi' className='tortinhas-img' />
                    </div>
                    <div className='linha-vertical'></div>
                    <div className='info'>
                        <h1>Le Cadi</h1>
                        <spam className="spam">GASTRONOMIA FAMILIAR</spam>
                        <div className='social-icons'>
                            <a href="https://wa.me/+5524988087860" target='_blank' rel='noreferrer'><img src={wppImg} alt='ícone do whatsapp' /></a>
                            <a href="https://instagram.com/lecadi__" target='_blank' rel='noreferrer'><img src={instaImg} alt='ícone do instagram' /></a>
                        </div>
                        <div className='links'>
                            <a href="https://wa.me/+5524988087860?text=Oi!+Vim+pelo+Instagram+do+LeCadi!" target='_blank' rel='noreferrer'>
                                <div className="link">
                                    <spam className='spam'>WhatsApp</spam>
                                    <img src={arrowImg} alt='Seta para direita' />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </main>
            <p className='copyrigth'>© 2024 Lecadi. Todos os direitos reservados.</p>
        </div>
    );
}

export default BioPage;
import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import BioPage from './pages/BioPage';

function App() {
    return (
        <Router>
            <Routes>
            <Route path="/" element={<Navigate to="/bio" replace />} />
                <Route path="/bio" element={<BioPage />} />
            </Routes>
        </Router>
    );
}

export default App;